import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
// import en_language from "../translations/en-language.json";
// import de_language from "../translations/de-language.json";
// import fr_language from "../translations/fr-language.json";

/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

const title = "Contoso Task Pane Add-in";

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} />
      </ThemeProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  const officeLanguage = Office.context.displayLanguage;
  const displayLanguage = officeLanguage.substring(0, 2);
  i18n.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    fallbackLng: "en",
    lng: displayLanguage,
    resources: {
      en: {
        translations: require("../translations/en-language.json"),
      },
      de: {
        translations: require("../translations/de-language.json"),
      },
      fr: {
        translations: require("../translations/fr-language.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });
  isOfficeInitialized = true;
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
