import * as React from "react";

import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogType,
  FontIcon,
  Icon,
  IconButton,
  IContextualMenuProps,
  IIconProps,
  Link,
  PrimaryButton,
} from "@fluentui/react";
import axios from "axios";
import { WithTranslation, withTranslation } from "react-i18next";
import Cookies from "js-cookie";

/* global , Office, */

Office.onReady(() => {
  Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, itemChanged);

  function itemChanged() {
    // let replyOptionsForm = document.getElementById("reply-options-form") as HTMLFormElement;
    // replyOptionsForm.reset();

    let importTextarea = document.getElementById("import-email-id") as HTMLTextAreaElement;
    importTextarea.innerText = "";
    importTextarea.value = "";

    let instructionsTextarea = document.getElementById("instructions-id") as HTMLTextAreaElement;
    instructionsTextarea.innerText = "";
    instructionsTextarea.value = "";

    let answerPreviewTextarea = document.getElementById("answer-preview") as HTMLTextAreaElement;
    answerPreviewTextarea.innerText = "";
    answerPreviewTextarea.value = "";
  }
});

interface AppProps extends WithTranslation {
  title: string;
  isOfficeInitialized: boolean;
}

interface AppState {
  accessToken: string;
  items: Array<object>;
  user: object;
  fund: any;
  validDate: any;
  dateFormat: any;
  username: string;
  password: string;
  isError: boolean;
  generatedText: string;
  numberOfWords: number;
  numberOfWordsInMail: number;
  notEnoughWords: boolean;
  instructions: string;
  replyTones: Array<any>;
  userSpecialities: Array<any>;
  preferredOptions: Array<any>;
  replyLengths: Array<any>;
  userJobs: Array<any>;
  answerLanguages: Array<any>;
  selectedTone: any;
  selectedJob: string;
  selectedPreferredOptions: Array<any>;
  selectedJobs: Array<any>;
  selectedPreferredOption: string;
  isCollapsed: boolean;
  isLoggedIn: boolean;
  selectedSpecialityOption: any;
  importedEmailText: string;
  answerLanguageOption: string;
  isLoading: boolean;
  allRequiredOptionsSelected: boolean;
  serverError: boolean;
  hideLoginErrorDialog: boolean;
  hideNotEnoughWordsDialog: boolean;
  hideRequiredOptionsErrorDialog: boolean;
  hideRequestServerErrorDialog: boolean;
  userIsRemembered: boolean;
}

const settingsIcon: IIconProps = { iconName: "Settings" };

const refreshIcon: IIconProps = { iconName: "Refresh" };

const fullScreen: IIconProps = { iconName: "FullScreen" };

class App extends React.Component<AppProps, AppState> {
  accessToken: string;
  rememberedAccessToken: string | null;
  dialog: any;
  officeContextInfo: any;
  officePlatform: any;
  loginError: any;
  registerLink: string;
  forgotPasswordLink: string;
  profileLink: string;
  gpt_options: string;
  dashboard: string;
  specialityColor: string;
  dateFormat: any;
  constructor(props) {
    super(props);
    this.state = {
      accessToken: "",
      items: [],
      user: {},
      fund: {},
      validDate: new Date(),
      dateFormat: "",
      username: "",
      password: "",
      isError: false,
      generatedText: "",
      numberOfWords: 0,
      numberOfWordsInMail: 0,
      notEnoughWords: false,
      instructions: "",
      replyTones: [],
      userSpecialities: [],
      preferredOptions: [],
      replyLengths: [],
      userJobs: [],
      answerLanguages: [],
      selectedTone: null,
      selectedPreferredOptions: [],
      selectedJobs: [],
      selectedPreferredOption: "",
      selectedJob: "",
      isCollapsed: false,
      isLoggedIn: false,
      selectedSpecialityOption: 0,
      importedEmailText: "",
      answerLanguageOption: "",
      isLoading: false,
      allRequiredOptionsSelected: true,
      serverError: false,
      hideLoginErrorDialog: false,
      hideNotEnoughWordsDialog: false,
      hideRequiredOptionsErrorDialog: false,
      hideRequestServerErrorDialog: false,
      userIsRemembered: false,
    };
    this.officeContextInfo = Office.context.diagnostics;
    this.officePlatform = this.officeContextInfo.platform;
  }

  componentDidMount(): void {
    const officeLanguage = Office.context.displayLanguage;
    const displayLanguage = officeLanguage.substring(0, 2);
    axios
      .get(`https://ehealthgpt.com/api/links`, {
        headers: {
          "Accept-Language": displayLanguage,
        },
      })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        this.registerLink = res.data.data.register;
        this.forgotPasswordLink = res.data.data["forgot_password"];
        this.profileLink = res.data.data.profile;
        this.gpt_options = res.data.data["gpt_options"];
        this.dashboard = res.data.data.dashboard;
      })
      .catch((error) => {
        console.log(error);
      });

    this.rememberedAccessToken = localStorage.getItem("accessToken");

    if (this.rememberedAccessToken) {
      this.rememberedAccessToken = localStorage.getItem("accessToken");
    }

    if (this.rememberedAccessToken) {
      this.setState({ isLoggedIn: true });

      const officeLanguage = Office.context.displayLanguage;
      const displayLanguage = officeLanguage.substring(0, 2);
      axios
        .get(`https://ehealthgpt.com/api/init`, {
          headers: {
            Authorization: `Bearer ${this.rememberedAccessToken}`,
            "Accept-Language": displayLanguage,
          },
        })
        .then((initRes) => {
          const items = initRes.data;
          this.setState({ user: items.data.user });
          this.setState({ fund: items.data.fund });
          this.setState({ validDate: items.data.fund["valid_until"] });
          if (localStorage.getItem("selectedTone"))
            this.setState({
              selectedTone: parseInt(localStorage.getItem("selectedTone") as string),
            });
          else if (items.data["gpt_tones"].length)
            this.setState({
              selectedTone: items.data["gpt_tones"][0].id,
            });
          this.setState({ replyTones: items.data["gpt_tones"] });
          if (localStorage.getItem("numberOfWords"))
            this.setState({
              numberOfWords: parseInt(localStorage.getItem("numberOfWords") as string),
            });
          else if (items.data["gpt_lengths"].length)
            this.setState({
              numberOfWords: items.data["gpt_lengths"][0]["number_of_words"],
            });
          this.setState({ replyLengths: items.data["gpt_lengths"] });
          if (JSON.parse(localStorage.getItem("selectedJobs") as string)) {
            this.setState({
              selectedJobs: JSON.parse(localStorage.getItem("selectedJobs") as string),
            });
          } else if (items.data["user_jobs"].length)
            this.setState({
              selectedJobs: items.data["user_jobs"],
            });
          this.setState({ userJobs: items.data["user_jobs"] });
          if (JSON.parse(localStorage.getItem("selectedPreferredOptions") as string)) {
            this.setState({
              selectedPreferredOptions: JSON.parse(localStorage.getItem("selectedPreferredOptions") as string),
            });
          } else if (items.data["gpt_preferred_options"].length)
            this.setState({
              selectedPreferredOptions: items.data["gpt_preferred_options"],
            });
          this.setState({ preferredOptions: items.data["gpt_preferred_options"] });
          if (localStorage.getItem("selectedSpeciality") !== null) {
            this.setState({
              selectedSpecialityOption: localStorage.getItem("selectedSpeciality"),
            });
          } else if (items.data["user_specialties"]) {
            this.setState({
              selectedSpecialityOption: items.data["user_specialties"][0].id,
            });
          }
          this.setState({ userSpecialities: items.data["user_specialties"] });
          if (localStorage.getItem("answerLanguage") !== null) {
            this.setState({
              answerLanguageOption: localStorage.getItem("answerLanguage"),
            });
          } else if (items.data["user_specialties"]) {
            this.setState({
              answerLanguageOption: " - ",
            });
          }
          this.setState({ answerLanguages: items.data.languages });

          if (localStorage.getItem("emailText") !== null) {
            this.setState({
              importedEmailText: localStorage.getItem("emailText"),
            });
          }

          if (localStorage.getItem("instructionsText") !== null) {
            this.setState({
              instructions: localStorage.getItem("instructionsText"),
            });
          }

          if (localStorage.getItem("answerText") !== null) {
            this.setState({
              generatedText: localStorage.getItem("answerText"),
            });
          }

          let validationDateString = this.state.validDate;
          let validationDate = new Date(validationDateString);
          let dateMDY = `${validationDate.getDate()}.${validationDate.getMonth() + 1}.${validationDate.getFullYear()}.`;

          this.setState({ dateFormat: dateMDY });

          setTimeout(() => {
            let specialityDropdown = document.getElementById("speciality-id") as HTMLSelectElement;
            let specialityOptions = specialityDropdown.options;
            for (let i = 0; i < specialityOptions.length; i++) {
              if (localStorage.getItem("selectedSpeciality") !== null) {
                this.setState({
                  selectedSpecialityOption: localStorage.getItem("selectedSpeciality"),
                });
              }
              if (this.state.selectedSpecialityOption === specialityOptions[i].value) {
                this.specialityColor = (specialityOptions as any)[i].attributes.color.value;
              }
            }
          }, 100);

          setTimeout(() => {
            let refreshButton = document.getElementsByClassName("refresh-icon")[0] as HTMLButtonElement;
            refreshButton.click();
          }, 100);
        })
        .catch((_error) => {
          this.setState({ isLoggedIn: false });
        });
    }
  }

  openEditAccount = () => {
    window.open(this.profileLink);
  };

  openEditOptions = () => {
    window.open(this.gpt_options);
  };

  // openHelpPage = () => {
  //   window.open("https://www.geeksforgeeks.org/");
  // };

  handleLogout = () => {
    if (localStorage.getItem("accessToken")) {
      this.rememberedAccessToken = localStorage.getItem("accessToken");
    } else {
      this.rememberedAccessToken = this.accessToken;
    }
    axios
      .post(
        `https://ehealthgpt.com/api/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.rememberedAccessToken}`,
          },
        }
      )
      .then((_res) => {
        this.setState({ importedEmailText: "" });
        this.setState({ instructions: "" });
        this.setState({ generatedText: "" });
        this.setState({ userIsRemembered: false });
        this.setState({ selectedPreferredOptions: [] });
        this.setState({ selectedJobs: [] });
        localStorage.removeItem("accessToken");
        localStorage.removeItem("selectedTone");
        localStorage.removeItem("selectedPreferredOptions");
        localStorage.removeItem("numberOfWords");
        localStorage.removeItem("selectedJobs");
        localStorage.removeItem("selectedSpeciality");
        localStorage.removeItem("answerLanguage");
        localStorage.removeItem("emailText");
        localStorage.removeItem("instructionsText");
        localStorage.removeItem("answerText");
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ isLoggedIn: false });
  };

  menuProps: IContextualMenuProps = {
    items: [
      {
        key: "editAccount",
        text: this.props.t("settings.edit-account"),
        onClick: this.openEditAccount,
      },
      {
        key: "editOptions",
        text: this.props.t("settings.edit-options"),
        onClick: this.openEditOptions,
      },
      // {
      //   key: "help",
      //   text: "Help",
      //   onClick: this.openHelpPage,
      // },
      {
        key: "logout",
        text: this.props.t("settings.logout"),
        onClick: this.handleLogout,
      },
    ],
    directionalHintFixed: true,
  };

  handleRegistrationPage = () => {
    window.open(this.registerLink);
  };

  handleForgotPasswordPage = () => {
    window.open(this.forgotPasswordLink);
  };

  handleBuyMoreWords = () => {
    window.open(this.dashboard);
  };

  handleUsernameChange = (event: { target: { value: any } }) => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = (event: { target: { value: any } }) => {
    this.setState({ password: event.target.value });
  };

  handleLogin = (e: any) => {
    e.preventDefault();

    axios
      .post(`https://ehealthgpt.com/api/login`, {
        grant_type: "password",
        client_id: 2,
        client_secret: "TkN8BVCdNwJSHMXVzWez9hdGUJlqR6nlHtNTwxsN",
        username: this.state.username,
        password: this.state.password,
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isLoggedIn: true });
          this.setState({ isError: false });
          this.accessToken = res.data.access_token;
          if (this.state.userIsRemembered === true) {
            localStorage.setItem("accessToken", this.accessToken);
          } else {
            localStorage.clear();
          }
          const officeLanguage = Office.context.displayLanguage;
          const displayLanguage = officeLanguage.substring(0, 2);
          axios
            .get(`https://ehealthgpt.com/api/init`, {
              headers: {
                Authorization: `Bearer ${this.accessToken}`,
                "Accept-Language": displayLanguage,
              },
            })
            .then((initRes) => {
              const items = initRes.data;
              this.setState({ user: items.data.user });
              this.setState({ fund: items.data.fund });
              this.setState({ validDate: items.data.fund["valid_until"] });
              if (localStorage.getItem("selectedTone"))
                this.setState({
                  selectedTone: parseInt(localStorage.getItem("selectedTone") as string),
                });
              else if (items.data["gpt_tones"].length)
                this.setState({
                  selectedTone: items.data["gpt_tones"][0].id,
                });
              this.setState({ replyTones: items.data["gpt_tones"] });
              if (localStorage.getItem("numberOfWords"))
                this.setState({
                  numberOfWords: parseInt(localStorage.getItem("numberOfWords") as string),
                });
              else if (items.data["gpt_lengths"].length)
                this.setState({
                  numberOfWords: items.data["gpt_lengths"][0]["number_of_words"],
                });
              this.setState({ replyLengths: items.data["gpt_lengths"] });
              this.setState({ userJobs: items.data["user_jobs"] });
              this.setState({ preferredOptions: items.data["gpt_preferred_options"] });
              if (localStorage.getItem("selectedSpeciality") !== null) {
                this.setState({
                  selectedSpecialityOption: localStorage.getItem("selectedSpeciality"),
                });
              } else if (items.data["user_specialties"]) {
                this.setState({
                  selectedSpecialityOption: items.data["user_specialties"][0].id,
                });
              }
              this.setState({ userSpecialities: items.data["user_specialties"] });
              if (localStorage.getItem("answerLanguage") !== null) {
                this.setState({
                  answerLanguageOption: localStorage.getItem("answerLanguage"),
                });
              } else if (items.data.languages) {
                this.setState({
                  answerLanguageOption: " - ",
                });
              }
              this.setState({ answerLanguages: items.data.languages });

              let validationDateString = this.state.validDate;
              let validationDate = new Date(validationDateString);
              let dateMDY = `${validationDate.getDate()}.${
                validationDate.getMonth() + 1
              }.${validationDate.getFullYear()}.`;

              let specialityDropdown = document.getElementById("speciality-id") as HTMLSelectElement;
              let specialityOptions = specialityDropdown.options;
              for (let i = 0; i < specialityOptions.length; i++) {
                if (this.state.selectedSpecialityOption === parseInt(specialityOptions[i].value, 10)) {
                  this.specialityColor = (specialityOptions as any)[i].attributes.color.value;
                }
              }

              this.setState({ dateFormat: dateMDY });
            });
        } else {
          this.setState({ isLoggedIn: false });
        }
      })
      .catch((error) => {
        this.setState({ isError: true });
        this.setState({ hideLoginErrorDialog: false });
        this.loginError = error;
        console.log(this.loginError);
      });
  };

  refreshApp = () => {
    if (localStorage.getItem("accessToken")) {
      this.rememberedAccessToken = localStorage.getItem("accessToken");
    } else {
      this.rememberedAccessToken = this.accessToken;
    }
    const officeLanguage = Office.context.displayLanguage;
    const displayLanguage = officeLanguage.substring(0, 2);
    axios
      .get(`https://ehealthgpt.com/api/init`, {
        headers: {
          Authorization: `Bearer ${this.rememberedAccessToken}`,
          "Accept-Language": displayLanguage,
        },
      })
      .then((initRes) => {
        const items = initRes.data;
        this.setState({ user: items.data.user });
        this.setState({ fund: items.data.fund });
        this.setState({ validDate: items.data.fund["valid_until"] });
        this.setState({ replyTones: items.data["gpt_tones"] });
        this.setState({ replyLengths: items.data["gpt_lengths"] });
        this.setState({ userJobs: items.data["user_jobs"] });
        this.setState({ preferredOptions: items.data["gpt_preferred_options"] });
        this.setState({ userSpecialities: items.data["user_specialties"] });
        this.setState({ answerLanguages: items.data.languages });

        let validationDateString = this.state.validDate;
        let validationDate = new Date(validationDateString);
        let dateMDY = `${validationDate.getDate()}.${validationDate.getMonth() + 1}.${validationDate.getFullYear()}.`;

        let specialityDropdown = document.getElementById("speciality-id") as HTMLSelectElement;
        let specialityOptions = specialityDropdown.options;
        for (let i = 0; i < specialityOptions.length; i++) {
          if (localStorage.getItem("selectedSpeciality") !== null) {
            this.setState({
              selectedSpecialityOption: localStorage.getItem("selectedSpeciality"),
            });
          }
          if (this.state.selectedSpecialityOption === parseInt(specialityOptions[i].value, 10)) {
            this.specialityColor = (specialityOptions as any)[i].attributes.color.value;
          }
        }

        this.setState({ dateFormat: dateMDY });
      });
  };

  handleToneButtonClick = (_event: any, toneId: any) => {
    this.setState({
      selectedTone: toneId,
    });
    localStorage.setItem("selectedTone", toneId);
  };

  handlePreferredOptionButtonClick = (dataId: any) => {
    let selectedPOptions = this.state.selectedPreferredOptions;
    const selected = selectedPOptions.findIndex((p) => p === dataId);

    if (selected == -1) {
      selectedPOptions.push(dataId);
    } else {
      selectedPOptions.splice(selected, 1);
    }
    this.setState({ selectedPreferredOptions: selectedPOptions });
    localStorage.setItem("selectedPreferredOptions", JSON.stringify(selectedPOptions));
  };

  handleLengthButtonClick = (_event: any, lengthNumber: number) => {
    this.setState({ numberOfWords: lengthNumber });
    localStorage.setItem("numberOfWords", lengthNumber.toString());
  };

  handleJobButtonClick = (dataId: any) => {
    let selectedJobOptions = this.state.selectedJobs;
    const selected = selectedJobOptions.findIndex((p) => p === dataId);

    if (selected == -1) {
      selectedJobOptions.push(dataId);
    } else {
      selectedJobOptions.splice(selected, 1);
    }
    this.setState({ selectedJobs: selectedJobOptions });
    localStorage.setItem("selectedJobs", JSON.stringify(selectedJobOptions));
  };

  handleLengthInput = (event: any) => {
    this.setState({ numberOfWords: parseInt(event.target.value, 10) });
    localStorage.setItem("numberOfWords", event.target.value);
  };

  rememberCredentialsHandler = () => {
    this.setState({ userIsRemembered: !this.state.userIsRemembered });
  };

  makeToneButton = (data: any) => {
    return (
      <DefaultButton
        key={data.id}
        style={{ borderRadius: "4px", marginBottom: "10px" }}
        className={this.state.selectedTone === data.id ? "active tone-active-button" : "inactive tone-inactive-button"}
        onClick={(event: any) => {
          this.handleToneButtonClick(event, data.id);
        }}
        required
      >
        {data.name}
      </DefaultButton>
    );
  };

  makeLengthButton = (data: any) => {
    return (
      <DefaultButton
        key={data.id}
        style={{ borderRadius: "4px", marginBottom: "10px" }}
        className={this.state.numberOfWords === data["number_of_words"] ? "active" : "inactive"}
        onClick={(event: any) => {
          this.handleLengthButtonClick(event, data["number_of_words"]);
        }}
        required
      >
        {data.name + " " + `(~${data["number_of_words"]})`}
      </DefaultButton>
    );
  };

  makePreferredOptionButton = (data: any) => {
    const selectedPO = this.state.selectedPreferredOptions.findIndex((p) => p === data.id);
    return (
      <DefaultButton
        key={data.id}
        style={{
          width: "100%",
          height: "50px",
          marginBottom: "10px",
          textAlign: "left",
          padding: "12.4px 5.5px 12.6px 14.5px",
        }}
        className={selectedPO > -1 ? "active preferred-button" : "inactive preferred-button"}
        onClick={() => {
          this.handlePreferredOptionButtonClick(data.id);
        }}
      >
        {data.name}
        {selectedPO > -1 && <Icon className="checkMark" iconName="CheckMark" />}
      </DefaultButton>
    );
  };

  makeJobButton = (data: any) => {
    const selectedJob = this.state.selectedJobs.findIndex((p) => p === data.id);
    return (
      <div className="jobs-button-wraper" key={data.id}>
        <DefaultButton
          style={{ height: "50px", textAlign: "left", padding: "12.4px 5.5px 12.6px 14.5px" }}
          className={selectedJob > -1 ? "active" : "inactive"}
          onClick={() => {
            this.handleJobButtonClick(data.id);
          }}
        >
          {data.label}
          {selectedJob > -1 && <Icon className="checkMark" iconName="CheckMark" />}
        </DefaultButton>
        <Icon
          className="jobIcon"
          style={{ marginLeft: "10px", fontWeight: "600" }}
          iconName="info"
          title={data["text_for_gpt"]}
        />
      </div>
    );
  };

  openImportEmailDialog = () => {
    let newTextArea = document.getElementById("import-email-id") as HTMLTextAreaElement;
    let dataString = newTextArea.value;
    let dataStringBase64 = window.btoa(dataString);
    let dataStringbase64Safe = encodeURIComponent(dataStringBase64);

    let colorString = this.specialityColor;
    let colorStringBase64 = window.btoa(colorString);
    let colorStringbase64Safe = encodeURIComponent(colorStringBase64);

    Office.context.ui.displayDialogAsync(
      "https://widget.ehealthgpt.com/import-into-mail.html?textValue=" + dataStringbase64Safe + "&color=" + colorStringbase64Safe,
      { height: 300, width: 400 },
      (asyncResult) => {
        this.dialog = asyncResult.value;
        this.dialog.addEventHandler(Office.EventType.DialogMessageReceived, this.sendFromImportDialog);
      }
    );
  };

  sendFromImportDialog = (arg) => {
    let messageFromDialog = arg.message;
    let importTextarea = document.getElementById("import-email-id") as HTMLTextAreaElement;
    importTextarea.value = messageFromDialog;
    this.setState({ importedEmailText: importTextarea.value });
    localStorage.setItem("answerText", importTextarea.value);
    this.dialog.close();
  };

  openAnswerPreviewDialog = () => {
    let answerTextarea = document.getElementById("answer-preview") as HTMLTextAreaElement;
    let dataString = answerTextarea.value;
    let dataStringBase64 = window.btoa(dataString);
    let dataStringbase64Safe = encodeURIComponent(dataStringBase64);

    let colorString = this.specialityColor;
    let colorStringBase64 = window.btoa(colorString);
    let colorStringbase64Safe = encodeURIComponent(colorStringBase64);
    Office.context.ui.displayDialogAsync(
      "https://widget.ehealthgpt.com/answer-preview.html?textValue=" + dataStringbase64Safe + "&color=" + colorStringbase64Safe,
      { height: 300, width: 400 },
      (asyncResult: { value: any }) => {
        this.dialog = asyncResult.value;
        this.dialog.addEventHandler(Office.EventType.DialogMessageReceived, this.sendFromAnswerDialog);
      }
    );
  };

  sendFromAnswerDialog = (arg) => {
    let messageFromDialog = arg.message;
    let answerTextarea = document.getElementById("answer-preview") as HTMLTextAreaElement;
    answerTextarea.value = messageFromDialog;
    this.setState({ generatedText: answerTextarea.value });
    localStorage.setItem("answerText", answerTextarea.value);
    this.dialog.close();
  };

  generateText = async (e: any) => {
    e.preventDefault();
    if (localStorage.getItem("accessToken")) {
      this.rememberedAccessToken = localStorage.getItem("accessToken");
    } else {
      this.rememberedAccessToken = this.accessToken;
    }
    let current = this;
    this.setState({ isLoading: true });
    await axios
      .post(
        `https://ehealthgpt.com/api/gpt-request`,
        {
          answer_language: this.state.answerLanguageOption,
          gpt_tone: this.state.selectedTone,
          gpt_preferred_options: this.state.selectedPreferredOptions,
          user_jobs: this.state.selectedJobs,
          gpt_length: this.state.numberOfWords,
          user_specialty: this.state.selectedSpecialityOption,
          import_email: this.state.importedEmailText,
          instructions: this.state.instructions,
        },
        {
          headers: {
            Authorization: `Bearer ${this.rememberedAccessToken}`,
          },
        }
      )
      .then((res) => {
        if (res !== null) {
          current.setState({ generatedText: res.data.result });
          localStorage.setItem("answerText", res.data.result);
        } else {
          current.setState({
            generatedText: localStorage.getItem("answerText"),
          });
        }
        this.setState({ allRequiredOptionsSelected: true });
        this.setState({ isLoading: false });
        const officeLanguage = Office.context.displayLanguage;
        const displayLanguage = officeLanguage.substring(0, 2);
        axios
          .get(`https://ehealthgpt.com/api/init`, {
            headers: {
              Authorization: `Bearer ${this.rememberedAccessToken}`,
              "Accept-Language": displayLanguage,
            },
          })
          .then((initRes) => {
            const items = initRes.data;
            this.setState({ fund: items.data.fund });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ isLoading: false });
        let importTextarea = document.getElementById("import-email-id") as HTMLTextAreaElement;
        let importTextareaValue = importTextarea.value;
        this.setState({ numberOfWordsInMail: importTextareaValue.split(" ").length });

        if (this.state.numberOfWords === null || isNaN(this.state.numberOfWords) || this.state.numberOfWords === 0) {
          this.setState({ allRequiredOptionsSelected: false });
          this.setState({ isLoading: false });
          this.setState({ hideRequiredOptionsErrorDialog: false });
        }
        if (this.state.fund["remaining_words"] < this.state.numberOfWordsInMail) {
          this.setState({ notEnoughWords: true });
          this.setState({ isLoading: false });
          this.setState({ hideNotEnoughWordsDialog: false });
        } else {
          this.setState({ notEnoughWords: false });
        }

        if(error.response.status === 500) {
          this.setState({ serverError: true });
          this.setState({ isLoading: false });
          this.setState({ hideRequestServerErrorDialog: false });
        }
        
      });
    // let current = this;
    // const configuration = new Configuration({
    //   apiKey: "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",
    // });
    // delete configuration.baseOptions.headers["User-Agent"];
    // const openai = new OpenAIApi(configuration);
    // const response = await openai.createCompletion({
    //   model: "text-davinci-003",
    //   prompt:
    //     `Turn the following text into a ${this.state.selectedTone} reply mail by doctor on ${this.state.answerLanguageOption} language: ` +
    //     this.state.importedEmailText,
    //   temperature: 0.7,
    //   max_tokens: this.state.numberOfWords,
    // });
    // this.setState({ isLoading: false });
    // current.setState({ generatedText: response.data.choices[0].text });
    // console.log(response);
  };

  insertIntoMail = () => {
    Office.context.mailbox.item.body.getTypeAsync((asyncResult) => {
      const bodyFormat = asyncResult.value;
      const finalText: any = this.state.generatedText
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/\n/g, "<br/>");
      Office.context.mailbox.item.body.setSelectedDataAsync(finalText, { coercionType: bodyFormat }, (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          console.log("Action failed with error: " + asyncResult.error.message);
          return;
        }
      });
    });
  };

  changeSpeciality = (event: any) => {
    this.setState({ selectedSpecialityOption: parseInt(event.target.value, 10) });
    localStorage.setItem("selectedSpeciality", event.target.value);
    let specialityDropdown = document.getElementById("speciality-id") as HTMLSelectElement;
    let specialityOptions = specialityDropdown.options;
    for (let i = 0; i < specialityOptions.length; i++) {
      if (event.target.value === specialityOptions[i].value) {
        this.specialityColor = (specialityOptions as any)[i].attributes.color.value;
      }
    }
  };

  importMailTextAreaHandler = (e: { target: { value: string } }) => {
    this.setState({ importedEmailText: e.target.value });
    localStorage.setItem("emailText", e.target.value);
  };

  instructionsTextAreaHandler = (e: { target: { value: string } }) => {
    this.setState({ instructions: e.target.value });
    localStorage.setItem("instructionsText", e.target.value);
  };

  answerPreviewTextAreaHandler = (e: { target: { value: string } }) => {
    this.setState({ generatedText: e.target.value });
    localStorage.setItem("answerText", e.target.value);
  };

  changeLanguage = (target: any) => {
    this.setState({ answerLanguageOption: target.target.value });
    localStorage.setItem("answerLanguage", target.target.value);
  };

  toggleHideRequiredOptionsErrorDialog = () => {
    this.setState({ hideRequiredOptionsErrorDialog: true });
  };

  toggleHideLoginErrorDialog = () => {
    this.setState({ hideLoginErrorDialog: true });
  };

  toggleHideNotEnoughWordsDialog = () => {
    this.setState({ hideNotEnoughWordsDialog: true });
  };

  toggleHideRequestServerErrorDialog = () => {
    this.setState({ hideRequestServerErrorDialog: true });
  };

  render() {
    const css = `

    :root {
      --color: ${this.specialityColor}; 
      --lightColor: ${this.specialityColor + "20"};
    }

    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

    * {
      box-sizing: border-box;
    }

    html {
      
      background-color: #f2f5f9;
    }

        .main-container {
          width: 100%;
          background-color: #f2f5f9;
        }

        .main-container > div {
          padding: 15px;
        }

        .login-container {
          width: 100%;
          padding: 53px 15px 67px 15px;
          border-radius: 10px;
          box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
          background-color: #fff;
        }

        .login-title {
          width: 100%;
          font-family: Inter, sans-serif;
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          text-align: center;
          color: #3f4254;
        }

        .login-subtitle {
          width: 100%;
          margin: 22px 1.8px 31.7px 1.7px;
          opacity: 0.6;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: center;
          color: #3f4254;
        }

        .error-container {
          width: 100%;
          padding-bottom: 10px;
          border-radius: 10px;
          box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
          background-color: #fff;
          margin-top: 20px;
        }

        .error-title {
          width: 100%;
          font-family: Inter, sans-serif;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          text-align: center;
          color: #3f4254;
          padding-top: 30px;
        }

        .error-description {
          width: 100%;
          margin: 22px 1.8px 31.7px 1.7px;
          opacity: 0.6;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: center;
          color: #3f4254;
        }

        .active {
          flex: 1 calc(50% - 5px);
          max-width: calc(50% - 5px);
          height: 42px;
          padding: 12.5px 0.5px 12.5px 0;
          border-radius: 4px;
          border: solid 1px var(--color);
          background-color: var(--lightColor);
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--color);
        }

        .active:hover {
          border: solid 1px var(--color);
          background-color: var(--lightColor);
          color: var(--color);
        }

        .preferred-button.active {
          flex: 1 100%;
          max-width: 100%;
        }

        .inactive {
          flex: 1 calc(50% - 5px);
          max-width: calc(50% - 5px);
          height: 42px;
          padding: 12.5px 0.5px 12.5px 0;
          border-radius: 4px;
          border: solid 1px #dadde0;
          background-color: #fff;
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #3f4254;
        }

        .inactive:hover {
          border: solid 1px #dadde0;
          background-color: #fff;
          color: #3f4254;
        }

        .preferred-button.inactive {
          flex: 1 100%;
          max-width: 100%;
        }

        .collapse-button {
          width: 100%;
          border-radius: 10px;
          background-color: #fff;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px #f2f0f0 solid;
          padding-bottom: 15px;
        }
        
        .collapse-content.collapsed {
          display: none;
        }
        
        .collapsed-content.expanded {
          display: block;
        }

        .input-group {
          width: 100%;
          height: 76.3px;
          margin-bottom: 10px;
        }

        .input-group > input {
          width: 100%;
          height: 42px;
          margin: 0 0.8px 0 0.2px;
          padding-left: 10px;
          border-radius: 4px;
          border-radius: 4px;
          border: solid 1px #dadde0;
          background-color: #fff !important;
        }
        .input-label {
          width: 100%;
          margin-bottom: 10px;
          font-family: Inter, sans-serif;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #3f4254;
        }

        .login-button {
          width: 100%;
          height: 42px;
          margin: 0 0.8px 0 0.2px;
          cursor: pointer;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          border-radius: 4px;
          border: solid 1px #da291c;
          background-color: #da291c;
        }

        .login-button:hover {
          color: #da291c;
          border: solid 1px #da291c;
          background-color: #fff;
        }

        .not-registered {
          width: 100%;
          margin: 0 0 4px;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.14px;
          text-align: center;
          color: #adb2bb;
        }

        .not-registered-link {
          width: 100%;
          margin: 4px 0 0;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.14px;
          text-align: center;
          color: #da291c;
        }

        .not-registered-link:active {
          color: #da291c;
        }

        .not-registered-link:hover {
          color: #da291c;
        }

        .forgot-password {
          width: 100%;
          margin: 0 0 4px;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.14px;
          text-align: center;
          color: #adb2bb;
        }

        .forgot-password-link {
          width: 100%;
          margin: 4px 0 0;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.14px;
          text-align: center;
          color: #da291c;
        }

        .forgot-password-link:active {
          color: #da291c;
        }

        .forgot-password-link:hover {
          color: #da291c;
        }

        .ms-Checkbox {
          text-decoration: none !important;
        }

        input + label {
          text-decoration: none;
        }

        input + label .ms-Checkbox-checkbox {
          border-color: #dadde0;
          background-color: #fff !important;
          border-radius: 4px !important;
          margin-bottom: 20px;
        }

        input:checked + label .ms-Checkbox-checkbox {
          border-color: #da291c !important;
          background-color: #da291c !important;
          border-radius: 4px !important;
        }

        .root-111:hover {
          color: #fff;
          background-color: var(--color);
        }

        .root-128 {
          color: #fff;
          background-color: var(--color);
        }

        .welcome-container {
          width: calc(100% + 30px);
          position: relative;
          background-color: var(--color);
          padding-bottom: 50px;
          margin-left: -15px;
          margin-right: -15px;
          margin-top: -15px;
        }

        .settings-icon {
          object-fit: contain !important;
          color: #fff !important;
          padding-right: 10px !important;
          padding-top: 20px !important;
          padding-bottom: 30px !important;
          display: flex !important;
          background-color: transparent !important;
          text-decoration: none !important;
        }

        .settings-icon .icon {
          display: none !important;
        }

        .settings-icon:hover {
          color: #fff !important;
          background-color: transparent !important;
          text-decoration: none !important;
        }

        .refresh-icon {
          color: #fff !important;
          margin-left: auto !important;
          padding-top: 20px !important;
          padding-bottom: 30px !important;
          display: flex !important;
          background-color: transparent !important;
        }

        .refresh-icon:hover {
          color: #fff !important;
          background-color: transparent !important;
        }

        .header-icons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .header-icons>button>span>i:not(:first-child) {
          display: none !important;
        }

        .root-121:hover {
          background-color: var(--color) !important;
          color: #fff !important;
        }

        .root-121:active {
          background-color: var(--color) !important;
          color: #fff !important;
        }

        .root-129 {
          background-color: var(--color) !important;
          color: #fff !important;
        }

        .welcome-title {
          width: 100%;
          margin: 0 0 5px;
          font-family: Inter, sans-serif;
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }

        .fund-name {
          width: 100%;
          margin: 5px 0 0;
          opacity: 0.6;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }

        .info-container {
          width: 100%;
          border-radius: 10px;
          padding: 20px 15px 16px 16.5px;
          box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
          background-color: #fff;
          margin-top: -60px;
          position: relative;
        }

        .checkMark {
          font-weight: 600;
        }

        .value-of-remained-words {
          width: 100%;
          font-family: Inter, sans-serif;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #3f4254;
        }

        .remained-words {
          width: 100%;
          opacity: 0.6;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #777f8e;
        }

        .valid-until {
          width: 100%;
          margin-top: 20px;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.14px;
          text-align: left;
          color: #adb2bb;
          border-top: 1px #f2f0f0 solid;
          padding-top: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .buy-more-words {
          width: 40%;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.14px;
          text-align: right;
          color: #da291c;
        }

        .root-110 {
          text-decoration: underline;
        }

        .root-110:hover {
          color: rgb(218 41 28);
          text-decoration: underline;
        }

        .info2-container {
          width: 100%;
          margin: 14px 0 0 0.5px;
        }

        .options-collapse {
          width: 100%;
          padding: 15px 15px 0px 15px;
          border-radius: 10px;
          box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
          background-color: #fff !important;
        }

        .options-text {
          width: 100%;
          margin: 0 42px 0 0;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #3f4254;
          text-transform: uppercase;
        }

        .options-collapse-icon {
          width: 17px;
          object-fit: contain;
          opacity: 0.8;
          background-color: transparent;
          font-weight: 600;
        }

        .select-component {
          width: 100%;
          height: 50px;
          padding: 12.5px 6px 12.5px 15px;
          border-radius: 4px;
          border: solid 1px #dadde0;
        }

        .option-title {
          font-family: Inter, sans-serif;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #3f4254;
          margin-bottom: 15px;
        }

        .requiredStar {
          font-size: inherit;
          font-weight: 600;
          color: var(--color);
        }

        .non-option-title {
          font-family: Inter, sans-serif;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #3f4254;
          margin-bottom: 15px;
          padding-top: 15px;
        }

        #toneButtons {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        #lengthButtons {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .custom-length-input {
          flex: 1 calc(50% - 5px);
          max-width: calc(50% - 5px);
          height: 42px;
          padding-left: 10px;
          border: solid 1px #dadde0;
          border-radius: 4px;
        }

        .jobs-button-wraper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }

        .jobs-button-wraper > button {
          flex: 1;
          max-width: initial;
        }

        .jobs-button-wraper > button > span, .preferre-wrapper > button > span {
          justify-content: space-between;
        }



        .import-email-area {
          width: 100%;
          padding: 5px 15px 0px 15px;
          border-radius: 10px;
          box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
          background-color: #fff !important;
          margin-top: 10px;
        }

        .answer-preview-area {
          width: 100%;
          padding: 5px 15px 0px 15px;
          border-radius: 10px;
          box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
          background-color: #fff !important;
          margin-top: 10px;
        }

        .popout-icon {
          background-color: transparent !important;
          color: rgb(63, 66, 84);
        }

        .popout-icon:hover {
          background-color: transparent !important;
        }

        .send-request-button {
          width: 100%;
          height: 42px;
          margin: 0 0.8px 0 0.2px;
          cursor: pointer;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          border-radius: 4px;
          border: solid 1px var(--color);
          background-color: var(--color);
          margin-bottom: 10px;
        }

        .send-request-button:hover {
          color: var(--color);
          border: solid 1px var(--color);
          background-color: #fff;
        }

        .insert-into-mail-button {
          width: 100%;
          height: 42px;
          margin: 0 0.8px 0 0.2px;
          cursor: pointer;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          border-radius: 4px;
          border: solid 1px var(--color);
          background-color: var(--color);
          margin-bottom: 10px;
        }

        .insert-into-mail-button:hover {
          color: var(--color);
          border: solid 1px var(--color);
          background-color: #fff;
        }

        .textarea-container {
          position: relative;
        }
        .textarea-container textarea {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding-top: 25px;
          padding-left: 10px;
          padding-right: 20px;
          border: solid 1px #dadde0;
          border-radius: 4px;
        }
        .textarea-container button {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: 20px;
        }

        .root-125:hover {
          background-color: var(--color);
          border: 1px solid var(--color);
          color: rgb(255, 255, 255);
        }

        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .loading-spinner {
          margin: auto;
          width: 100px;
          height: 100px;
          border: 10px solid var(--lightColor);
          border-top: 10px solid var(--color);
          border-radius: 50%;
          animation: spinner 1.5s linear infinite;
        }

        .request-error-container {
          width: 100%;
          border-radius: 10px;
          padding: 20px 15px 16px 16.5px;
          box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
          background-color: #fff;
          margin-top: -10px;
          position: relative;
        }

        .request-error-title {
          width: 100%;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #3f4254;
        }

        .request-error-content {
          width: 100%;
          opacity: 0.6;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #777f8e;
        }

        .jobIcon:hover {
          cursor: pointer;
          background-color: transparent !important;
        }
        `;
    const isLoggedIn = this.state.isLoggedIn;
    const isLoading = this.state.isLoading;
    const isError = this.state.isError;
    const serverError = this.state.serverError;
    const notEnoughWords = this.state.notEnoughWords;
    const allRequiredOptionsSelected = this.state.allRequiredOptionsSelected;
    const hideLoginErrorDialog = this.state.hideLoginErrorDialog;
    const hideNotEnoughWordsDialog = this.state.hideNotEnoughWordsDialog;
    const hideRequiredOptionsErrorDialog = this.state.hideRequiredOptionsErrorDialog;
    const hideRequestServerErrorDialog = this.state.hideRequestServerErrorDialog;
    const lengthInWordsErrorDialogContentProps = {
      type: DialogType.normal,
      title: this.props.t("error.lengthInWords-title"),
      subText: this.props.t("error.lengthInWords-text"),
    };
    const notEnoughWordsErrorDialogContentProps = {
      type: DialogType.normal,
      title: this.props.t("error.notEnoughWords-title"),
      subText: this.props.t("error.notEnoughWords-text"),
    };
    const loginErrorDialogContentProps = {
      type: DialogType.normal,
      title: this.props.t("error.login-title"),
      subText: this.props.t("error.login-text"),
    };
    const requestServerErrorDialogContentProps = {
      type: DialogType.normal,
      title: this.props.t("error.serverError-title"),
      subText: this.props.t("error.serverError-text"),
    };
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = {
      isBlocking: true,
      styles: modalPropsStyles,
    };

    return (
      <div>
        <style>{css}</style>

        <main id="main-id" className="main-container">
          {!isLoggedIn && (
            <div>
              <div className="login-container">
                <div className="login-title">{this.props.t("login.title")}</div>
                <div className="login-subtitle">{this.props.t("login.subtitle")}</div>
                <form className="form" onSubmit={this.handleLogin}>
                  <div className="input-group">
                    <div className="input-label">{this.props.t("login.email-title")}:</div>
                    <input
                      id="input-email-id"
                      type="email"
                      name="email"
                      required
                      onChange={this.handleUsernameChange}
                    />
                  </div>
                  <div className="input-group">
                    <div className="input-label">{this.props.t("login.password-title")}:</div>
                    <input
                      id="input-password-id"
                      type="password"
                      name="password"
                      required
                      onChange={this.handlePasswordChange}
                    />
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    {this.state.userIsRemembered === true && (
                      <Checkbox
                        label={this.props.t("login.remember")}
                        checked={true}
                        onChange={this.rememberCredentialsHandler}
                      />
                    )}
                    {this.state.userIsRemembered === false && (
                      <Checkbox label={this.props.t("login.remember")} onChange={this.rememberCredentialsHandler} />
                    )}
                  </div>
                  {isError && (
                    <Dialog
                      hidden={hideLoginErrorDialog}
                      onDismiss={this.toggleHideLoginErrorDialog}
                      dialogContentProps={loginErrorDialogContentProps}
                      modalProps={modalProps}
                    ></Dialog>
                  )}
                  <button type="submit" className="login-button">
                    {this.props.t("login.login-button-title")}
                  </button>
                  <div style={{ marginTop: "20px" }}>
                    <div className="not-registered">{this.props.t("login.not-registered")}?</div>
                    <Link className="not-registered-link" onClick={this.handleRegistrationPage} underline>
                      {this.props.t("login.not-registeredLink")}.
                    </Link>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <div className="forgot-password">{this.props.t("login.forgot-password")}?</div>
                    <Link className="forgot-password-link" onClick={this.handleForgotPasswordPage} underline>
                      {this.props.t("login.forgot-passwordLink")}.
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          )}
          {isLoggedIn && (
            <div>
              <div id="welcome-id" className="welcome-container">
                <div className="header-icons">
                  <IconButton className="refresh-icon" iconProps={refreshIcon} onClick={this.refreshApp} />
                  <IconButton className="settings-icon" menuProps={this.menuProps} iconProps={settingsIcon} />
                </div>
                <div style={{ paddingBottom: "30px" }}>
                  <div className="welcome-title">
                    {this.props.t("welcome.title")}, {this.state.user["first_name"]} {this.state.user["last_name"]}
                  </div>
                  <div className="fund-name">{this.state.fund.name}</div>
                </div>
              </div>
              <div className="info-container">
                <div className="value-of-remained-words">{this.state.fund["remaining_words"]}</div>
                <div className="remained-words">{this.props.t("info.remaining-words")}</div>

                {this.state.validDate ? (
                  <div className="valid-until">
                    {this.props.t("info.valid-until")}: {this.state.dateFormat}
                    {/* <Link id="buy-more-words-id" className="buy-more-words" onClick={this.handleBuyMoreWords}>Buy more words</Link> */}
                  </div>
                ) : (
                  <div className="valid-until">
                    {this.props.t("info.valid-until")}: -
                    {/* <Link id="buy-more-words-id" className="buy-more-words" onClick={this.handleBuyMoreWords}>Buy more words</Link> */}
                  </div>
                )}
              </div>
              <div style={{ marginTop: "15px" }}>
                <div>
                  <form method="post" id="reply-options-form" onSubmit={this.generateText}>
                    <div className="options-collapse">
                      <span
                        className="collapse-button"
                        onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })}
                      >
                        <span className="options-text">{this.props.t("options.title")}</span>{" "}
                        <FontIcon
                          className="options-collapse-icon"
                          iconName={this.state.isCollapsed === false ? "ChevronUp" : "ChevronDown"}
                        />
                      </span>
                      <div
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        className={`collapse-content ${this.state.isCollapsed ? "collapsed" : "expanded"}`}
                        aria-expanded={this.state.isCollapsed}
                      >
                        <div className="option-title">
                          {this.props.t("options.tone")}: <span className="requiredStar"> * </span>
                        </div>
                        <div id="toneButtons">{this.state.replyTones?.map(this.makeToneButton, this)}</div>
                        <div style={{ marginTop: "5px" }}>
                          <div className="option-title">
                            {this.props.t("options.speciality")}: <span className="requiredStar"> * </span>
                          </div>
                          <div>
                            <select
                              id="speciality-id"
                              className="select-component"
                              value={this.state.selectedSpecialityOption}
                              onChange={this.changeSpeciality}
                            >
                              {this.state.userSpecialities?.map((el) => (
                                <option key={el.id} value={el.id} color={el["css_class"]}>
                                  {el.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <div className="option-title">{this.props.t("options.preferred-options")}:</div>

                          <div className="preferre-wrapper">
                            {this.state.preferredOptions?.map(this.makePreferredOptionButton, this)}
                          </div>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          <div className="option-title">{this.props.t("options.jobs")}:</div>

                          {this.state.userJobs?.map(this.makeJobButton, this)}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <div className="option-title">
                            {this.props.t("options.length")}: <span className="requiredStar"> * </span>
                          </div>

                          <div id="lengthButtons">
                            {this.state.replyLengths?.map(this.makeLengthButton, this)}
                            <input
                              className="custom-length-input"
                              type={"number"}
                              onChange={this.handleLengthInput}
                              min="0"
                              placeholder={this.props.t("customLength.title")}
                            ></input>
                          </div>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          <div className="option-title">{this.props.t("options.answer-in-language")}:</div>
                          <div>
                            <select
                              id="answer-language-id"
                              className="select-component"
                              value={this.state.answerLanguageOption}
                              onChange={this.changeLanguage}
                            >
                              <option defaultValue={" - "}> - </option>
                              {this.state.answerLanguages?.map((el) => (
                                <option key={el.label} value={el.name}>
                                  {el.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="import-email-area" style={{ marginTop: "15px" }}>
                      <div>
                        <div className="non-option-title">
                          {this.props.t("importEmail.title")}: <span className="requiredStar"> * </span>
                        </div>
                        <div className="textarea-container">
                          <textarea
                            id="import-email-id"
                            style={{ borderRadius: "4px", height: "300px" }}
                            defaultValue={this.state.importedEmailText as any}
                            onChange={(e: any) => this.importMailTextAreaHandler(e)}
                            cols={40}
                            required
                          />
                          {this.officePlatform === "OfficeOnline" && (
                            <IconButton
                              className="popout-icon"
                              style={{ color: "rgb(63, 66, 84)" }}
                              iconProps={fullScreen}
                              onClick={this.openImportEmailDialog}
                            ></IconButton>
                          )}
                        </div>
                        <div className="non-option-title">{this.props.t("instructions.title")}:</div>
                        <div className="textarea-container">
                          <textarea
                            id="instructions-id"
                            style={{ borderRadius: "4px", height: "50px" }}
                            defaultValue={this.state.instructions as any}
                            onChange={(e) => this.instructionsTextAreaHandler(e)}
                            cols={40}
                          />
                        </div>
                      </div>
                      <p>
                        <PrimaryButton id="send-request-button-id" className="send-request-button" type="submit">
                          {this.props.t("sendRequest.button-title")}
                        </PrimaryButton>
                      </p>
                    </div>
                  </form>
                </div>
                <div className="answer-preview-area">
                  {notEnoughWords && !isLoading && (
                    <Dialog
                      hidden={hideNotEnoughWordsDialog}
                      onDismiss={this.toggleHideNotEnoughWordsDialog}
                      dialogContentProps={notEnoughWordsErrorDialogContentProps}
                      modalProps={modalProps}
                    ></Dialog>
                  )}
                  {!allRequiredOptionsSelected && !isLoading && (
                    <Dialog
                      hidden={hideRequiredOptionsErrorDialog}
                      onDismiss={this.toggleHideRequiredOptionsErrorDialog}
                      dialogContentProps={lengthInWordsErrorDialogContentProps}
                      modalProps={modalProps}
                    ></Dialog>
                  )}
                  {serverError && !isLoading && (
                    <Dialog
                      hidden={hideRequestServerErrorDialog}
                      onDismiss={this.toggleHideRequestServerErrorDialog}
                      dialogContentProps={requestServerErrorDialogContentProps}
                      modalProps={modalProps}
                    ></Dialog>
                  )}
                  {isLoading && <div className="loading-spinner"></div>}
                  {!isLoading && (
                    <div>
                      <div className="non-option-title"> {this.props.t("answerPreview.title")}</div>
                      <div className="textarea-container">
                        <textarea
                          id="answer-preview"
                          style={{ borderRadius: "4px", height: "300px" }}
                          defaultValue={this.state.generatedText as any}
                          onChange={(e) => this.answerPreviewTextAreaHandler(e)}
                          rows={10}
                          cols={40}
                        />
                        {this.officePlatform === "OfficeOnline" && (
                          <IconButton
                            className="popout-icon"
                            style={{ color: "rgb(63, 66, 84)" }}
                            iconProps={fullScreen}
                            onClick={this.openAnswerPreviewDialog}
                          ></IconButton>
                        )}
                      </div>
                      <span><i>{this.props.t("chatGPT.note")}</i></span>
                    </div>
                  )}
                  <p>
                    <PrimaryButton
                      id="insert-into-mail-button-id"
                      className="insert-into-mail-button"
                      onClick={this.insertIntoMail}
                    >
                      {this.props.t("insertIntoMail.button-title")}
                    </PrimaryButton>
                  </p>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    );
  }
}
export default withTranslation()(App);
declare global {
  interface Window {
    text_value: any;
    answer_text_value: any;
    sendFromImportDialog: Function;
    sendFromAnswerDialog: Function;
  }
}
